<template>
    <div id="home">
        <div class="show">
            <div class="channel"></div>
            <div class="part1">
                <slides/>
            </div>
            <div class="part2">
                <div class="nav">
                    <nav ref="nav" @touchstart="navTouchStart($event)" @touchend="navTouchEnd($event)" class="left">
                        <a v-for="(s, index) in series" :key="s.seriesId" @click="navClick(index)" href="javascript:;" :class="s.seriesId==seriesCurrent.seriesId? 'current' : ''">{{ s.sign }}</a>
                    </nav>
                </div>
                <div class="list">
                    <a v-for="(a, index) in articles" :key="a.articleId" :href="a.href" @click="link(a.articleId, index)" class="article">
                        <div class="left"><img :src="a.thumbnail"></div>
                        <div class="right">
                            <h3>{{ a.sign }}</h3>
                            <h4>{{ a.summary }}</h4>
                            <div class="other">
                                <img src="../assets/image/icon-date-2.png">
                                {{ a.postDate }}
<!--                                <img src="../assets/image/icon-view-count-2.png">-->
<!--                                浏览量{{ a.viewCount }}-->
                            </div>
                        </div>
                    </a>
                </div>
                <div class="no-more"><img src="../assets/image/no-more.png"></div>
            </div>
        </div>
        <footer><img src="../assets/image/footer.png"></footer>
    </div>
</template>

<style scoped>
    .show { width:100%; padding:50px 27px 0 27px; }
    .show .channel { height:77px; margin-bottom:-14px; background:url(../assets/image/title.png) no-repeat left top; background-size:507px 77px; }
    .show .part1 { width:100%; height:auto; padding:12px; border-radius:15px; background:url(../assets/image/banner-bg.png) no-repeat center top #fff; background-size:696px 378px; z-index:99; }
    .show .part2 { margin-top:40px; border-radius:10px 10px 15px 15px; background-color:#fff; }
    .show .part2 .nav { position:relative; width:100%; height:72px; border-radius:10px 10px 0 0; background-color:#def1f8; overflow:hidden; }
    .show .part2 .nav nav { position:absolute; top:0; bottom:0; width:800px; height:72px; display:flex; justify-content:flex-start; transition:all 0.3s; }
    .show .part2 .nav nav.left { left:0; }
    .show .part2 .nav nav.right { right:0; }
    .show .part2 .nav nav a { width:160px; height:72px; line-height:72px; display:inline-block; font-size:26px; color:#080b10; text-align:center; }
    .show .part2 .nav nav a.current { border-radius:10px 10px 0 0; font-weight:bold; color:#fff; background-color:#00b8ee; }
    .show .part2 .list { padding:60px 20px 0 20px; }
    .show .part2 .list a.article { margin-bottom:60px; display:flex; justify-content:space-between; align-items:stretch; opacity:1; }
    .show .part2 .list a.article:first-child { margin-top:0; }
    .show .part2 .list a.article .left { width:240px; height:172px; border-radius:10px; margin-right:40px; overflow:hidden; }
    .show .part2 .list a.article .left img { width:240px; height:172px; }
    .show .part2 .list a.article .right { width:376px; line-height:1; }
    .show .part2 .list a.article .right h3 { margin:0; line-height:38px; font-size:28px; overflow:hidden; color:#000; display:-webkit-box; -webkit-line-clamp:2; text-overflow:ellipsis; -webkit-box-orient:vertical; }
    .show .part2 .list a.article .right h4 { margin:15px 0 0 0; font-size:24px; font-weight:normal; color:#8d8ea1; white-space:nowrap; text-overflow:ellipsis; overflow:hidden; }
    .show .part2 .list a.article .right .other { margin-top:20px; display:flex; justify-content:flex-start; align-items:center; font-size:22px; color:#bfc5d2; }
    .show .part2 .list a.article .right .other img { width:26px; height:26px; margin:0 10px 0 20px; }
    .show .part2 .list a.article .right .other img:first-child { margin-left:0; }
    .show .part2 .no-more { height:88px; line-height:88px; border-top:1px solid #f3f4f7; text-align:center; }
    .show .part2 .no-more img { width:250px; height:23px; }
    footer { position:relative; width:750px; height:20px; margin-top:20px; overflow:hidden; }
    footer img { position:absolute; left:0; top:0; width:750px; height:20px; }
</style>

<script>
    import slides from "@/components/slides";

    export default {
        name: 'home',
        components: {
            slides
        },
        data() {
            return {
                series: [],
                seriesCurrent: {},
                articles: []
            }
        },
        created() {
            (async () => {
                let that = this;
                // 获取栏目
                await this.$axios.post(this.$global.config.serverApi+'/article_series.php', { action:'list', status:'open' }).then( function(response) {
                    if (response.data.complete === true) {
                        that.series = response.data.articleSeries;
                        that.seriesCurrent = that.series[0];
                    } else {
                        throw new Error(response.data.error);
                    }
                }).catch( function(error) {
                    that.$store.commit('setAlert', error.message);  // 弹出错误提示
                });
                // 获取文章
                this.getArticles();

                console.log(this.$refs.nav.classList);
            })();
        },
        methods: {
            getArticles() {  // 获取文章
                let that = this;
                this.articles = [];
                this.$axios.post(this.$global.config.serverApi+'/article.php', { action:'list', seriesId:this.seriesCurrent.seriesId, page:0, rowsPerPage:0 }).then( function(response) {
                    if (response.data.complete === true) {
                        that.articles = response.data.articles;
                    } else {
                        throw new Error(response.data.error);
                    }
                }).catch( function(error) {
                    that.$store.commit('setAlert', error.message);  // 弹出错误提示
                });
            },
            navTouchStart(e) {
                this.navStartX = e.touches[0].clientX;
            },
            navTouchEnd(e) {
                this.navEndX = e.changedTouches[0].clientX;
                if (this.navEndX - this.navStartX > 10) {
                    this.$refs.nav.classList.remove("right");
                    this.$refs.nav.classList.add("left");
                } else if (this.navEndX - this.navStartX < -10) {
                    this.$refs.nav.classList.remove("left");
                    this.$refs.nav.classList.add("right");
                }
            },
            navClick(index) {
                this.seriesCurrent = this.series[index];
                if (index == 0) {
                    this.$refs.nav.classList.remove("right");
                    this.$refs.nav.classList.add("left");
                }
                if (index >= 4) {
                    this.$refs.nav.classList.remove("left");
                    this.$refs.nav.classList.add("right");
                }
                this.getArticles();
            },
            link(articleId, index) {
                let that = this;
                this.$axios.post(this.$global.config.serverApi+'/article.php', { action:'viewCountIncrease', articleId:articleId }).then( function(response) {
                    if (response.data.complete === true) {
                        that.articles[index].viewCount = response.data.article.viewCount;
                    } else {
                        throw new Error(response.data.error);
                    }
                }).catch( function(error) {
                    that.$store.commit('setAlert', error.message);  // 弹出错误提示
                });
            }
        }
    }
</script>
