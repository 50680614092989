<template>
    <div id="slides">
        <el-carousel ref="slide" :interval="4000" arrow="never">
            <div @touchstart="slideStart($event)" @touchmove="slideMove($event)">
                <el-carousel-item v-for="(s, index) in slides" :key="s.slideId">
                    <a :href="s.article.href" @click="link(s.articleId, index)" class="img"><img :src="s.src"></a>
                    <div class="sign">
                        <div class="title"><a :href="s.article.href" @click="link(s.articleId, index)">{{ s.article.sign }}</a></div>
                        <div class="other">
                            <img src="../assets/image/icon-date.png">
                            <span>{{ s.article.postDate }}</span>
                            <!--                                <img src="../assets/image/icon-view-count.png">-->
                            <!--                                浏览量{{ s.article.viewCount }}-->
                        </div>
                    </div>
                </el-carousel-item>
            </div>
        </el-carousel>
    </div>
</template>

<style scoped>
    #slides>>>.el-carousel__container { height:507px; }
    #slides>>>.el-carousel__item { width:672px; height:483px; }
    #slides>>>.el-carousel__item a.img { width:672px; height:340px; display:block; border-radius:15px; overflow:hidden; }
    #slides>>>.el-carousel__item a.img img { width:672px; height:340px; }
    #slides>>>.el-carousel__item .sign { padding:30px 20px; border-radius:0 0 10px 10px; background-color:#fff; }
    #slides>>>.el-carousel__item .sign .title { height:33px; line-height:33px; margin-bottom:22px; font-size:30px; white-space:nowrap; text-overflow:ellipsis; overflow:hidden; }
    #slides>>>.el-carousel__item .sign .title a { color:#000; }
    #slides>>>.el-carousel__item .sign .other { height:28px; display:flex; justify-content:flex-start; align-items:center; font-size:24px; color:#8d8ea1; }
    #slides>>>.el-carousel__item .sign .other img { width:28px; height:28px; margin:0 10px 0 20px; }
    #slides>>>.el-carousel__item .sign .other img:first-child { margin-left:0; }
    #slides>>>.el-carousel__item .sign .other span { height:28px; line-height:28px; margin-top:2px; display:inline-block; }
    #slides>>>.el-carousel__indicators {left:30px; right:30px; bottom:170px; text-align:right; transform:none; }
    #slides>>>.el-carousel__indicator .el-carousel__button { width:16px; height:16px; border-radius:8px; background-color:#d6d6d6; opacity:1; }
    #slides>>>.el-carousel__indicator.is-active .el-carousel__button { background-color:#5ab7e5; }
</style>

<script>
    export default {
        name: "slides",
        data() {
            return {
                slides: []
            }
        },
        created() {
            let that = this;
            // 获取幻灯片
            this.$axios.post(this.$global.config.serverApi+'/slide.php', { action:'list', status:'open' }).then( function(response) {
                if (response.data.complete === true) {
                    that.slides = response.data.slides;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        },
        methods: {
            slideStart(e) {
                this.startX = e.touches[0].clientX;
                this.startY = e.touches[0].clientY;
            },
            slideMove(e) {
                this.moveX = e.touches[0].clientX;
                this.moveY = e.touches[0].clientY;
                let nowtime = new Date().getTime();
                if (this.startTime == undefined || nowtime > this.startTime) {
                    if (this.startX - this.moveX <= 0) { // 右滑触发
                        this.slidePrev();
                        return false;
                    } else {
                        this.slideNext();
                        return false;
                    }
                }
            },
            slidePrev() {
                this.$refs.slide.prev();
                this.startTime = new Date().getTime() + 500;
            },
            slideNext() {
                this.$refs.slide.next();
                this.startTime = new Date().getTime() + 500;
            },
            link(articleId, index) {
                let that = this;
                this.$axios.post(this.$global.config.serverApi+'/article.php', { action:'viewCountIncrease', articleId:articleId }).then( function(response) {
                    if (response.data.complete === true) {
                        that.slides[index].article.viewCount = response.data.article.viewCount;
                    } else {
                        throw new Error(response.data.error);
                    }
                }).catch( function(error) {
                    that.$store.commit('setAlert', error.message);  // 弹出错误提示
                });
            }
        }
    }
</script>
